import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="listing-selection"
export default class extends Controller {
  static targets = ['container']

  connect() {
    if (this.containerTarget.getAttribute('data-listing-kind') == 'new_listing_link_') {
      return
    }

    let links = this.containerTarget.getElementsByClassName('listings-links')
    for(let link of links) {
      if (this.containerTarget.getAttribute('data-listing-kind') != link.getAttribute('id')) {
        link.classList.add('listing-layout__box--invisible')
      }
    }
  }

  toggle (event) {
    console.log(event.target.closest('a').getAttribute('id'))
    let links = this.containerTarget.getElementsByClassName('listings-links')

    for(let link of links) {
      if (event.target.closest('a').getAttribute('id') != link.getAttribute('id')) {
        link.classList.add('listing-layout__box--invisible')
      }
      else {
        link.classList.remove('listing-layout__box--invisible')
      }
    }
  }
}
