import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

// Connects to data-controller="listing"
export default class extends Controller {
  static targets = ['field']

  connect() {
    try {
      if (this.excludedFields) { this.excludedFields = null }
      this.changeFieldsMethod(this.fieldTarget)
    } catch(e) {
    }
  }

  choose(event) {
    let urls = JSON.parse(event.target.getAttribute('data-urls'))
    let value = event.target.value

    get(urls[value], {
      responseKind: 'turbo-stream'
    })
  }

  changeFieldsMethod(element) {
    let excludeFields = JSON.parse(element.getAttribute('data-exclude-fields'))
    let notShowFields = []

    for (let fieldId of excludeFields) {
      let elField = document.getElementById(fieldId)
      if (!elField) { continue }

      notShowFields.push(fieldId)

      this.excludedFields = this.excludedFields || {}
      this.excludedFields[fieldId] = this.excludedFields[fieldId] || {}
      this.excludedFields[fieldId]['html'] = elField.innerHTML
      elField.innerHTML = ""
    }

    for (let field in excludeFields) {
      if (!this.excludedFields && !this.excludedFields[field]) { break }

      for (let showField in this.excludedFields[field]) {
        if (notShowFields.includes(showField)) { continue }

        document.getElementById(showField).innerHTML = this.excludedFields[field][showField]
      }
    }
  }

}
