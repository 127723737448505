// import { Controller } from "@hotwired/stimulus"

// // Connects to data-controller="listing"
// export default class extends Controller {
//   static targets = ['menu', 'button']

//   connect() {
//   }

//   toggleMenu(ev) {
//     if (this.menuTarget.classList.contains('active')) {
//       this.menuTarget.classList.remove('active')
//       this.menuTarget.classList.add('mobile-hidden')
//       this.buttonTarget.classList.remove('open')
//     } else {
//       this.menuTarget.classList.add('active')
//       this.menuTarget.classList.remove('mobile-hidden')
//       this.buttonTarget.classList.add('open')
//     }
//   }
// }
