import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["dialog", "input", "content", "action"];

  connect() {
    if (this.dialogTarget.hasAttribute('data-closed')) {
      this.close(null)
    }
  }

  open(event) {
    this.dialogTarget.classList.add("modal-open");
    this.dialogTarget.style.display = "block";
    document.body.classList.add("overflow-hidden");
  }

  close(event) {
    this.dialogTarget.classList.remove("modal-open");
    this.dialogTarget.style.display = "none";
    
    document.body.classList.remove("overflow-hidden");
  }
}
