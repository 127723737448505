import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["conversation", "panel", "sidebar"];

  connect() {
    console.log(this.panelTarget)
  }

  openMessage() {
    this.sidebarTarget.classList.add('is-mobile--closed')
    this.sidebarTarget.classList.remove('is-mobile--open')
    this.panelTarget.classList.remove('is-mobile--closed')
    this.panelTarget.classList.add('is-mobile--open');
    // this.sidebarTarget.classList.add('animate__animated','animate__zoomOut');
    // this.panelTarget.classList.add('animate__animated','animate__zoomIn');
  }

  closeConversation() {
    console.log('clicked')
    this.sidebarTarget.classList.remove('is-mobile--closed')
    this.sidebarTarget.classList.add('is-mobile--open')
    this.panelTarget.classList.remove('is-mobile--open')
    this.panelTarget.classList.add('is-mobile--closed')
    // this.panelTarget.classList.add('animate__animated','animate__zoomOut');
    // this.sidebarTarget.classList.add('animate__animated','animate__zoomIn');
  }
}