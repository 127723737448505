import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="feedback"
export default class extends Controller {
  static targets = ["option"];

  next(event) {
    let currentSection = document.getElementById(
      event.target.getAttribute("data-current")
    );
    let nextSection = document.getElementById(
      event.target.getAttribute("data-target")
    );

    currentSection.classList.add("hidden");
    nextSection.classList.remove("hidden");
  }

  change(event) {
    console.log("batata");
    this.index = this.optionTargets.indexOf(event.currentTarget);
    console.log(this.index);
  }

  option() {
    this.optionTargets.forEach((option, index) => {
      option.classList.toggle("active", index == this.index);
    });
  }

  get index() {
    return parseInt(this.data.get("index") || 0);
  }

  set index(value) {
    this.data.set("index", value);
    this.option();
  }
}
