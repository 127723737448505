import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="favourite"
export default class extends Controller {
  static targets = ['icon']

  connect() {
  }

  toggleState(ev) {
    if (this.iconTarget.classList.contains('active')) {
      this.iconTarget.classList.remove('active')
      this.iconTarget.classList.remove('fa-solid')
      this.iconTarget.classList.add('fa-light')
      this.iconTarget.classList.add('animate__bounceIn')

      setTimeout(() => {
        this.iconTarget.classList.remove('animate__bounceIn')
      }, 1000);
    } else {
      this.iconTarget.classList.add('active')
      this.iconTarget.classList.add('animate__bounceIn')
      this.iconTarget.classList.remove('fa-light')
      this.iconTarget.classList.add('fa-solid')

      setTimeout(() => {
        this.iconTarget.classList.remove('animate__bounceIn')
      }, 1000);
    }
  }
}
