import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="scroll"
export default class Scroll extends Controller {
  static targets = ['messages']
  static messages;

  connect() {
    Scroll.messages = this.messagesTarget;
    this.resetScroll();
    Scroll.messages.addEventListener("DOMNodeInserted", this.resetScroll);
  }

  resetScroll() {
    const bottomOfScroll = Scroll.messages.scrollHeight - Scroll.messages.clientHeight;
    const isLoaded = document.getElementById('messages_pagination');

    // Scroll down if we're not within the threshold
    if (isLoaded.getAttribute('data-loaded') == 'true') {
      Scroll.messages.scrollTop = bottomOfScroll;
    }
  }

  scrollToBottom() {
    // hack to wait for the render of messages content
    setTimeout(() => {
      this.messagesTarget.scrollTop = this.messagesTarget.scrollHeight - this.messagesTarget.clientHeight;
    }, 300);
  }
}