import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["content"];

  connect() {
    setTimeout(() => this.removeOnTime(this.contentTarget), 5000);
  }

  removeEl(target) {
    if (target.parentElement.children.length <= 1) {
      target.parentElement.remove();
    } else {
      target.remove();
    }
  }

  removeOnTime(content) {
    content.style.transform = "translateX(120%)";
    setTimeout(() => this.removeEl(this.contentTarget), 1000);
  }

  hide(event) {
    this.removeOnTime(this.contentTarget);
  }
}
