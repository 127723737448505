import { Controller } from "@hotwired/stimulus"
import $ from "jquery"

// Connects to data-controller="select-conversation"
export default class extends Controller {
  static targets = ['conversation']

  connect() {
  }

  toggle(ev) {
    $(".conversation-link").filter(function (index, element) {
      return element !== this.conversationTarget;
    }).removeClass('conversation-link--active');

    this.conversationTarget.classList.add('conversation-link--active')
  }
}
