import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="autosubmit"
export default class extends Controller {
  connect() {
  }

  submit(event) {
    let form = event.target.closest('form')
    Turbo.navigator.submitForm(form)
  }
}
