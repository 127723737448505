import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="listing"
export default class extends Controller {
  static targets = ['input']

  connect() {
    console.log(this.inputTarget)
  }

  showPasswordVisibility() {
    if (this.inputTarget.type === 'password') {
      this.inputTarget.type = 'text'
    } else {
      this.inputTarget.type = 'password'
    }
  }
}