import $ from "jquery";
import { Controller } from "@hotwired/stimulus";
import select2 from "select2";
select2();

export default class extends Controller {
  connect() {
    $(this.element).select2({
      closeOnSelect: false,
      placeholder: this.placeholder,
      allowHTML: true,
      allowClear: true,
      multiple: this.multiple
    });

    if (!this.element.dataset.event || this.element.dataset.event === '') return 

    let selectElement = this.element
    $(this.element).on('change', function (event) {
      let result = selectElement.dispatchEvent(new CustomEvent(selectElement.dataset.event))
    })
  }
  disconnect() {
    $(this.element).select2("destroy");
  }
  get placeholder() {
    return this.element.dataset.placeholder || "Selecione";
  }
  get allowClear() {
    return this.element.dataset.allowClear || true;
  }
  get multiple() {
    return this.element.dataset.multiple || "multiple";
  }
}
